.map {
    text-align: center;
    &__img {
        border-bottom: 20px solid $green-2;
        text-align: center;

        img {
            width: 100%;
            
            @include desktop {
                max-width: 1170px;
                box-shadow: $card-shadow;
                border-radius: 20px;
                border: 8px solid white;
            }
            margin: 0 auto;
        }
    }
    
    .mapouter {
        position:relative;
        text-align:right;
        width:100%;
        height:550px;
    }
    .gmap_canvas {
        overflow:hidden;
        background:none!important;
        width:100%;
        height:550px;
    }
    .gmap_iframe {
        height:550px!important;
    }

    button {
        @include desktop {
            display: none;
        }
    }
}