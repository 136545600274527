.characteristics {
    position: relative;
    background-image: url('../../assets/images/about4@2x.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    text-align: center;
    padding: 80px 0 140px;
    
    @include mobile {
        background-image: none;
        padding: 40px 0;
        box-shadow: $card-shadow;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(31, 35, 39, 1);
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &__card {
        border-radius: $border-radius;
        width: 370px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mobile {
            width: 320px;
        }

        &-img {

            img {
                width: 100%;
            }
        }

        &-content {
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        h4 {
            font-weight: 800;
            line-height: 37px;
            margin-bottom: 24px;

            @include desktop {
                width: 85%;
            }
        }
    }

    &__content {
        position: inherit;

        > div {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 30px;

            @include tablet {
                flex-wrap: wrap;
                justify-content: center;
            }


            @include mobile {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &__modal {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mobile {
            flex-direction: column;
        }

        > div {

            @include mobile {
                &:not(:last-child)
                 {
                     margin-bottom: 40px;
                 }
            }

            @include desktop {
                width: 50%;
                &:nth-child(even) {
                    padding-left: 32px;
                }
    
                &:nth-child(n+3) {
                    margin-top: 40px;
                }
            }
        }

        h5 {
            text-align: left;
            margin-bottom: 24px;
        }
    }
}
