.contact {

    .hs-form-frame {
        width: 100%;
    }

    @include desktop {
        padding-top: 2px;
        padding-bottom: 70px;
        border-bottom: 20px solid $gray-2;
    }

    @include tablet-mobile{
        background-color: white;
    }
    
    > div {

        @include tablet-mobile{
            padding: 0;
        }

        @include desktop {
            display: flex;
            align-items: flex-start;
        }
    }
    
    &__info {

        @include tablet-mobile{
            padding: 40px 20px;
            box-shadow: $card-shadow;
            margin-top: -40px;
        }

        @include desktop {
            background-color: white;
            border-radius: $border-radius;
            box-shadow: $card-shadow;
            padding: 70px;
            width: 50%;
            margin-top: -120px;
        }

        &-item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            &:first-child {
                align-items: flex-start;
            }

            a:hover {
                color: $green-2;
            }

            span {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
            }

            p {
                font-weight: 500;

                &.contact__info-item--wp {
                    margin-left: 10px;
                }
            }

            p, h5 {
                margin-left: 20px;

                @include tablet-mobile{
                    font-size: $fs-md;
                    font-weight: 500;
                }
            }

            h5 {
                @include desktop {
                    text-transform: uppercase;
                }

                @include tablet-mobile{
                    text-transform: capitalize;
                }
            }

            &--fb {

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    &__form {

        @include tablet-mobile{
            background-color: $gray-1;
            padding: 40px 20px 50px;
            border-top: 20px solid $gray-2;
        }

        @include desktop {
            margin-left: 50px;
            width: 50%;
        }

        &-field {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            label {
                font-size: 11px;
                margin-bottom: 8px;
            }

            input,
            textarea {
                border: 1px solid #4F627A73;
                border-radius: 5px;
                outline: none;
                padding: 14px 20px;
                color: $text-color;

                &:focus,
                &:focus-visible {
                    border-color: #4F627A;
                }
            }

            input {
                height: 50px;
                width: 100%;

                @include desktop {
                    max-width: 370px;
                }
            }

            textarea {
                min-height: 200px;
            }

            &-error {
                label {
                    color: $error;
                }

                input {
                    border-color: #D33A3A9C;
                }
                
                &-msg {
                    margin-top: 8px;
                    color: $error;

                    @include tablet-mobile{
                        font-size: $fs-md;
                    }
                }
            }
        }

        button {
            width: 100%;
        }

        &-success {
            margin-top: 30px;
            color: $green-2;
            font-weight: 500;
            line-height: 30px;
        }
    }
}