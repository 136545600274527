.galerie {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
    justify-content: space-evenly;
    padding-top: 30px;
    @include desktop {
        padding-bottom: 120px;
    }

    @include mobile {
        padding-top: 120px;
        gap: 30px;
        padding-bottom: 90px;
    }


    ._3AWTh {
        height: 100%;

        &::before {
            background: rgba(31, 35, 39, 0.9);
            height: 100%;
        }

        @include mobile-landscape {
            > div:last-child {
                display: none !important;
            }
        }
        img {
            @include desktop {
                border-radius: 10px;
            }

            @include mobile {
                max-width: 100vw !important;
            }
        }
        ._3ZUEV {
            top: 0;
            right: 0;
            ._3bkri {
                background-color: #262A2D;
                border-radius: 0 0 0 5px;
                width: 48px;
                height: 48px;

                svg {
                    display: none;
                }

                &::after {
                    content:  '\f00d';
                    font: $font-awesome;
                    color: white;
                    font-size: 25px;
                    position: absolute;
        
                    @include mobile {
                        font-size: 20px;
                    }
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    ._20cg_ {
        background-color: #262A2D;
        width: 48px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            width: 38px;
            height: 58px;
        }

        svg {
            display: none;
        }

        &:focus {
            box-shadow: none;
        }

         &::after {
            font: $font-awesome;
            color: white;
            font-size: 25px;
            position: absolute;

            @include mobile {
                font-size: 20px;
            }
        }
    }

    ._18xMp {
        right: 0;

        @include mobile {
            top: 46%;
        }

        @include mobile-landscape {
            top: 41%;
        }
        ._20cg_ {
            border-radius: 2px 0 0 2px;

            &::after {
                content: '\f054';
            }
        }
    }

    ._2pWTS {
        left: 0;
        @include mobile {
            top: 46%
        }
        @include mobile-landscape {
            top: 41%;
        }
        ._20cg_ {
            border-radius: 0 2px 2px 0;

            &::after {
                content: '\f053';
            }
        }
    }

    &__card {
        box-shadow: $card-shadow;
        padding: 40px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include mobile {
            padding: 20px;
        }

        &__date {
            color: $text-color;
            font-size: $fs-sm;
        }

        &__text {
            line-height: 20px;
        }

        &__images {

            .grid-container {
                .container {
                    padding: 0;

                    .height-one {
                        padding-top: 50%;
                    }
                    .height-two {
                        padding-top: 25%;
                    }
                }
            }
            img {
                width: 100%;
                height: 370px;
                object-fit: cover;
                cursor: pointer;
            }
        }
    }
}

.ReactModalPortal {
    .ril__image {

        @include desktop {
            width: 70%;
        }
    }
}