@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Yesteryear&display=swap');

// COLORS

$green-1: #00BC70;
$green-2: #00985B;
$green-3: #8FD1B6;
$green-light: #CEEBDF;
$error: #D33A3A;


$gray-1: #F8F9FB;
$gray-2: #F0F2F5;

$text-color-dark: #1F2327;
$text-color: #736D7C;
$title-color: #302B39;


// FONTS

$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Yesteryear', cursive;
$font-awesome: normal 300 16px 'Font Awesome 5 Pro';

$fs-xs: 12px;
$fs-sm: 14px;
$fs-md: 16px;
$fs-lg: 18px;

$card-shadow: 0px 43px 75px #00000029;
$nav-shadow: 0px 6px 6px #00000026;

$border-radius: 20px;