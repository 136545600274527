.finantare {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-evenly;
    gap: 30px;

    @include mobile {
        flex-direction: column;
        padding-bottom: 90px;
    }

    @include desktop {
        padding-bottom: 120px;
    }

    &__card {
        width: 270px;
        text-align: center;

        @include mobile {
            width: 100%;
        }

        @include tablet-mobile {
            &:last-child {
                margin-bottom: 90px;
            }
        }

        h4 {
            font-weight: 800;
            margin: 0 auto 24px;
        }

        &__icon {
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid $green-2;
        }

        button {
            @include mobile {
                width: 100%;
            }
        }
    }

    &__pdf {
        margin-top: 90px;
        @include mobile {
            width: 100%;
        }
        button {

            @include mobile {
                width: 100%;
            }
        }
    }
}