.tabs {
    border-bottom:  1px solid #D2D3D4;
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    li {
        padding: 0 40px 16px;
        font-size: 22px;
        font-weight: 800;
        color: $title-color;
        text-transform: uppercase;
        cursor: pointer;

        &.is-active {
            color: $green-2;
            border-bottom: 4px solid $green-2;
            position: relative;
            display: flex;
            justify-content: center;
    
            &:after {
                content: '';
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid $green-2;
                position: absolute;
                bottom: -11px;
            }
        }
    }
}