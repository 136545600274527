.float-menu {
    height: 55px;
    margin-bottom: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .breadcrumbs {
        @include desktop {
            display: none;
        }
    }

    @include tablet-mobile {
        margin-bottom: 50px;
    }

    @include desktop {
        border-bottom:  1px solid #D2D3D4;
    }
    
    @include tablet-mobile {
        &::after {
            content: '';
            height: 1px;
            background-color: #D2D3D4;
            position: absolute;
            width: 100%;
            bottom: 4px;
        }
    }

    &.floating {
        background-color: white;
        box-shadow: $nav-shadow;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        z-index: 9;
        margin-bottom: 0;
        border: none;
        height: auto;

        &::after {
            content: none;
        }

        .breadcrumbs {
            margin-bottom: 16px;
            padding-left: 16px;
            display: flex;
            align-items: baseline;
            align-self: flex-start;
            width: 100%;
            a:last-child {
                margin-right: 16px;
            }

            @include desktop {
                display: none;
            }

            span {
                cursor: pointer;
                margin-right: 16px;
            }
        }
        .page-layout__breadcrumb {
            justify-content: flex-start;
        }
    }

    ul {
        display: flex;
        align-items: center;
        height: 100%;

        @include tablet-mobile {
            overflow-x: scroll;
            overflow-y: hidden;
            max-width: 100%;
            /* width */
            &::-webkit-scrollbar {
                height: 0.05rem;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: transparent;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: transparent;
            }
        }

        li {
            font-size: 22px;
            font-weight: 800;
            text-transform: uppercase;
            padding: 16px 40px;
            color: $title-color;
            cursor: pointer;
            white-space: nowrap;

            @include mobile {
                padding: 16px 20px;
                font-size: $fs-lg;
            }

            &.is-active, 
            &.tab-active {
                color: $green-2;
                border-bottom: 4px solid $green-2;
                position: relative;
                display: flex;
                justify-content: center;

                @include desktop {
                    &:after {
                        content: '';
                        width: 0; 
                        height: 0; 
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 8px solid $green-2;
                        position: absolute;
                        bottom: -11px;
                    }
                }
                
            }

        }
    }

}