

.prototype {
    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet-mobile {
            flex-wrap: wrap;
        }
    }

    &-col {
        text-align: center;
        padding-bottom: 40px;
        margin: 0 auto 8px;

        @include desktop {
            width: 50%;
        }
    }
    &__imgcontainers {
        top: 0;
        left: 0;

        position: relative;
    }
    &__btn
    {
        background-color: #ddd;
        border: none;
        color: black;
        padding: 16px 32px;
        text-align: center;
        font-size: 16px;
        margin: 4px 2px;
        transition: 0.3s;
        &:hover
        {
            background-color: #3e8e41;
            color: white;
        }
    }
    &__btnactive
    {
        background-color: #3e8e41;
        border: none;
        color: white;
        padding: 16px 32px;
        text-align: center;
        font-size: 16px;
        margin: 4px 2px;
        transition: 0.3s;
        &:hover
        {
            background-color: #3e8e41;
            color: white;
        }
    }
    &__svgs {
            width:100%;
            height:auto;
            position:absolute; 
            z-index: 10;


            &__cls-1:hover {
                opacity:0.42;
                
            }
        
              &__cls-1 {
                fill: #00985b;

                opacity:0.42;
                
              }
        
              &__cls-2 {
                fill: #00985b;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 36px;
              }
        
              &__cls-3 {
                font-size: 301.67px;
                fill: #fff;
                font-family: Arial-Black, Arial;
                font-weight: 800;
              }
        
              &__cls-4 {
                fill: #f4f4f6;
                opacity: 0;
              }
    }

    &__png {

            width:100%;
            height:auto;
            position:relative; 

    }

}
