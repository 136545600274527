.section-title {
    margin-bottom: 50px;
    z-index: 9;
    position: inherit;
    text-align: center;

    @include mobile {
        margin-bottom: 30px;
    }
    
    &__font {
        font-family: $font-secondary;
        font-size: 45px;
        margin-bottom: 8px;
        color: $green-2;

        @include mobile {
            font-size: 37px;
        }
    }

    h3 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 14px;

        @include mobile {
            font-size: 30px;
        }
    }

    
    &--white {

        .section-title__font {
            color: white;
        }

        h3 {
            color: white;
        }
        
        svg, path {
            fill: #ffffff;
        }
    }
}