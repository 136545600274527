.page-layout {
    min-height: 100%;
    width: 100%;
    position: absolute;
    // top: 175px;
    top: 540px;


    @include tablet-mobile {
        top: 247px;
    }


    &--gray {

        @include desktop {
            // top: 102px;
            top: 540px;
        }

        .page-layout__content {
            @include tablet-mobile {
                background-color: $gray-1;
            }
            // @include desktop {
            //     background-image: url('../../assets/images/hero-svg-gray.svg');
            //     padding-top: 472px;
            // }

        }
    }

    &__img {
        position: relative;
        background-image: url('../../assets/images/page-head.png');
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        max-height: 475px;
        height: 100%;
        border-bottom: 5px solid $green-2;

        @include tablet-mobile {
            height: 131px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        
        @include desktop {
            margin-top: -100px;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 475px;
            padding-top: 80px;
        }

        h1 {
            font-size: 55px;
            color: $green-2;
            letter-spacing: 4.07px;
            text-transform: uppercase;
            font-weight: 900;
            margin: 0;

            @include tablet-mobile {
                font-size: 27px;
                top: unset;
                letter-spacing: 2px;
                text-align: center;
            }
        }

        @include tablet-mobile {
            top: 50px;
        }
    }

    &__breadcrumb {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;

        @include tablet-mobile {
            overflow: scroll;
            overflow-y: hidden;
            padding-bottom: 8px;

            /* width */
            &::-webkit-scrollbar {
                height: 0.05rem;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: transparent;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: transparent;
            }
        }

        @include tablet-mobile {
            margin-top: 16px;
        }

        &-item {
            text-transform: uppercase;
            font-size: $fs-xs;
            white-space: nowrap;
            
            &--active {
                color: $green-2;
                font-weight: 600;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    background-color: $green-2;
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: -8px;
                }
            }

        }
        span {
            margin: 0 10px;
            font-size: $fs-xs;
            color: $text-color;
            display: inline-block;
        }

        .active {
            background-color: transparent;
        }
    }

    &__content {
        // background-image: url('../../assets/images/hero-svg.svg');
        // background-position: top;
        // background-size: 120%;
        // background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        // padding-top: 400px;

        @include tablet-mobile {
            background-image: none;
            padding-top: 40px;
        }
    }
}