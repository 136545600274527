.image-bar {
    background-color: $green-2;
    padding:  30px 0;

    > div {
        display: flex;
        justify-content: space-between;

        @include tablet-mobile {
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 20px;
        }
        
        > div {
            border-radius: $border-radius;
            background-color: white;
            align-items: center;
            justify-content: center;

            @include desktop {
                
                &:not(:last-child) {
                    margin-right: 30px;
                
                }
            }

            &:hover {
                &:after {
                    content: '\f002';
                    font: $font-awesome;
                    color: $green-2;
                    font-size: 60px;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.8);
                    position: absolute;
                    border-radius: $border-radius;
                    pointer-events: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

        }
        
    }
    div[data-rmiz-wrap="visible"] {
        img {
            width: 270px;
            height: 202px;
            border-radius: $border-radius;
        }
    }
}

div[data-rmiz-modal-content="true"] {

    img {
        width: 100%;
    }
}
