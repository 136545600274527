.step {
    padding: 80px 0;

    @include mobile {
        background-color: $gray-2;
        padding: 40px 0;
    }

    &__content {
        background-color: $gray-2;
        padding-top: 110px;

        @include desktop {
            height: 402px;
        }
    
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include tablet {
                flex-wrap: wrap;
                justify-content: center;
            }

            @include mobile {
                flex-direction: column;
            }
        }
    
    }
    &__card {
        background-color: white;
        box-shadow: $card-shadow;
        border-radius: $border-radius;
        padding: 30px;
        width: 330px;
        height: 372px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            width: 320px;
            height: 372px;
        }
         &:not(:last-child) {

            @include tablet {
                margin-right: 30px;
            }
             @include tablet-mobile {
                 margin-bottom: 130px;
             }
         }

        &__badge {
            background-color: $gray-2;
            width: 180px;
            height: 180px;
            box-shadow: inset 0px 10px 6px #00000014;
            border: 10px solid white;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #00985B;
            font-size: 79px;
            font-weight: 800;
            margin: -130px auto 0;
            border-color: $green-2;

            span {
                font-size: 22px;
                font-weight: 800;
                line-height: 30px;
            }
        }

        ul {
            text-align: center;

            li {
                font-size: $fs-md;
                font-weight: 500;
                line-height: 22px;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }

        &:last-child {
            .step__card__badge {
                border-color: white;
                
            }
        }
    }
}