.text {
    &--500 {
        font-weight: 500;
    }

    &--bold {
        font-weight: bold;
    }

    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }
    
}
