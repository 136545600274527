.about {
    &__img {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @include tablet-mobile {
            margin-bottom: 24px;
        }
        @include desktop { 
            
        }

        img {
            width: 80%;

            @include desktop {
                box-shadow: $card-shadow;
                border-radius: 20px;
                border: 8px solid white;
            }
        }
    }
    padding: 100px 0;

    @include tablet-mobile {
        padding: 40px 0 0;
    }

    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet-mobile {
            flex-direction: column-reverse;
        }

        @include desktop {

            &:nth-child(odd) {
                flex-direction: row-reverse;
    
                .about-col {
                    &:first-child {
                        margin-left: 55px;
                    }
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }

    }

    &-col {
        text-align: left;

        @include desktop {
            
            &:last-child {
                margin-left: 55px;
            }
        }


        @include tablet-mobile {
            img {
                width: 100%;
                border-radius: 10px;
            }
        }
        h5 {
            margin-bottom: 24px;

            @include tablet-mobile {
                margin-bottom: 14px;
                margin-top: 20px;
            }
        }

        p {
            color: $text-color;
            line-height: 30px;

            @include tablet-mobile {
                margin-bottom: 30px;
            }
        }
    }
}