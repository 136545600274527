.footer {

    @include tablet-mobile {
        padding-bottom: 63px;
    }
    
    &__pre-footer {
        background-image: url('../../assets/images/footer-image.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 80px;
        text-align: center;

        &__phone {
            background-color: #CEEBE0;
            border-radius: 30px;
            max-width: 518px;
            width: 100%;
            padding: 10px;
            margin: 30px auto 0;

            @include tablet-mobile {
                max-width: 318px;
                border-radius: 15px;
            }

            > div {
                background-color: white;
                box-shadow: 0px 7px 13px #00000040;
                border-radius: 25px;
                display: grid;
                grid-template-columns: 120px 1fr;

                @include tablet-mobile {
                    grid-template-columns: 70px 1fr;
                    border-radius: 10px;
                }
            }

            &-icon {
                padding: 30px 0;
                background-color: $green-3;
                border-radius: 25px 0 0 25px;

                @include tablet-mobile {
                    padding: 16px 0;
                    border-radius: 10px 0 0 10px;
                }

                i {
                    font-size: 60px;
                    background-color: #007245;
                    color: transparent;
                    text-shadow: 0px 2px 3px rgb(0 217 130 / 0.3);
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    background-clip: text;

                    @include tablet-mobile {
                        font-size: 40px;
                    }
                }
            }

            &-number {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 40px;
                font-weight: bold;

                @include tablet-mobile {
                    font-size: 28px;
                }
            }

            &:hover {
                background-color: $green-3;
                .footer__pre-footer__phone {
                    &-icon {
                        background-color: #69C7A1;
                    }

                    &-number {
                        color: $green-2;
                    }
                }
            }
        }
        @include mobile {
            padding: 40px 16px;
        }

        h3 {
            @include mobile {
                font-size: 44px;
            }
        }

        h4 {
            @include mobile {
                font-size: 28px;
            }
        }

        h3, h4 {
            color: white;

            @include desktop {
                margin-bottom: 8px;
            }
        }

        a {
            img {
                filter: drop-shadow(0px 8px 30px #0000004D);
                margin-top: 40px;
                border-radius: 30px;

                @include mobile {
                    width: 100%;
                    margin-top: 30px;
                    border-radius: 20px;
                }

                @include tablet {
                    width: 50%;
                }

                &:last-child {
                    display: none;
                }
            }

            &:hover {
                img {
                    &:first-child {
                        display: none;
                    }
                    &:last-child {
                        display: initial;
                    }
                }
            }
        }
    }

    &__main-footer {

        @include tablet-mobile {
            display: none;
        }

        &__top {
            background-color: $green-2;
            font-size: 38px;
            line-height: 50px;
            font-weight: 800;
            color: white;
            padding: 24px;

            > div {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                grid-gap: 30px;
            }

            &-brand {
                position: relative;
                a {
                    position: absolute;
                    top: -80px;
                    right: 50%;
                    transform: translate(50%);

                    img {
                        filter: drop-shadow(0px 12px 43px #00000029);
                        border-radius: 10px;
                    }
                }
            }
        }

        &__content {
            background-color: $gray-1;
            padding: 40px 24px;

            > div {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: flex-start;
                grid-gap: 30px;
            }

            h5 {
                margin-top: 110px;
                text-align: center;
            }

            &-credits {
                color: $green-2;
                padding-top: 16px;
                text-align: center;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 9px;
                    margin-top: 16px;
                    filter: drop-shadow(0px 3px 6px #0000001C);
                }
            }

            &-pages {
                li {
                    a {
                        padding-bottom: 16px;
                        border-bottom: 2px solid $gray-2;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
    
                        &::before {
                            content:  '\f105';
                            font: $font-awesome;
                            font-size: $fs-lg;
                            color: $green-2;
                            margin-right: 16px;
                        }
    
                        &:hover {
                            color: $green-2;
                            border-color: $green-2;
                        }
                    }

                    &:not(:first-child) {
                        a {
                            padding-top: 20px;
                        }
                    }
                }
            }
        }
        &__bottom {
            background-color: $gray-2;
            padding: 16px 24px;
            font-size: $fs-xs;

            > div {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                grid-gap: 30px;
            }

            &-copy {
                color: $text-color;
                text-align: center;
            }

            a:hover {
                text-decoration: underline;
            }

            &-fb {
                color: #1777F1;
                font-weight: 600;

                a {
                    display: flex;
                    align-items: center;

                    &:hover {
                        text-decoration: none;
                        color: $green-2;

                        path {
                            fill: $green-2;
                        }
                    }
                }

                svg {
                    width: 31px;
                    height: 31px;
                }

                span {
                    margin-left: 8px;
                }
            }
        }
    }

    &__note {
        font-size: $fs-xs;
        line-height: 16px;
        color: $text-color;
        padding: 16px 24px;
        background-color: #e9eaed;
    }
}