.hero {
    overflow: hidden;
    position: relative;
    max-height: 900px;
    border-bottom: 5px solid $green-2;

    @include tablet-mobile{
        max-height: 380px;
        height: auto;
    }

    video {
        object-fit: cover;
        width: 100%;
        @include tablet-mobile{
            object-fit: contain;
        }
    }

    &__button {
        position: absolute;
        top: -16px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .button {
            font-size: 16px;
            height: 45px;
        }
        
        
        @include desktop {
            top: 0;
            align-items: center;
            .button {
                font-size: 24px;
                height: 75px;
            }
        }
    }
}