.etapa-row {
    display: flex;
    height: 155px;
    width: 100%;
    border-radius: $border-radius;

    @include desktop {
        background-color: $gray-2;
    }

    @include tablet-mobile {
        flex-direction: column;
        align-items: center;
    }


    h4 {
        text-align: left;

        @include tablet-mobile {
            text-align: center;
            font-size: 22px;
            line-height: 30px;
        }

        &:first-child {
            font-weight: 800;
        }
    }

    &__icon {
        background-color: $green-3;
        padding: 30px;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet-mobile {
            width: 270px;
        }

        &-step {
            font-size: 97px;
            color: white;
            font-weight: 800;
            margin-left: 26px;
        }
    }
    
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;

        @include tablet-mobile {
            padding:  20px 0;
        }
    }
}