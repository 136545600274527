@mixin desktop {
    @media screen and (min-width: 1024px) {
        @content
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content
    }
}

@mixin tablet-mobile {
    @media screen and (max-width: 1023px) {
        @content
    }
}


@mixin mobile-landscape {
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: landscape) { 
        @content
    }
}