.mb-hide {
    @include tablet-mobile {
        display: none;
    }
}

.dt-hide {
    @include desktop {
        display: none;
    }
}

.width-100 {
    width: 100%;
}

.price {
    font-size: 48px;
    color: $green-2;
    font-weight: 800;
    letter-spacing: 1.78px;
    display: flex;
    justify-content: center;
    margin: 12px 0;
    
    &.old {
        text-decoration: line-through;
        font-size: 28px;
        font-weight: 500;

        span {
            font-size: 14px;
        }
    }

    span {
        font-weight: 400;
        font-size: 24px;
    }
}

.invert {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.overflow-hidden {
    overflow: hidden;
}

.list {
    text-align: left;
    li {
        font-size: $fs-md;
        line-height: 22px;
        font-weight: 500;

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        @include tablet-mobile {
            margin-bottom: 24px;
        }

        &::before {
            content: '\f00c';
            font: $font-awesome;
            color: $green-2;
            margin-right: 16px;
        }
    }
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.loading {
    text-align: center;
    height: 60vh;
    svg {
        margin-top: 80px;
    }
}

.cursor-pointer {
    cursor: pointer;
}