.alte-proiecte {
    padding: 80px 0;

    @include tablet-mobile {
        padding: 40px 0;
    }

    &__content {

        @include tablet-mobile {
            grid-template-rows: 1fr 1fr;
            gap: 20px;
        }
        @include desktop {
            grid-template-columns: 1fr 1fr;
            gap: 30px;
            
        }
        > a {
            position: relative;
            img {
                width: 100%;
                border-radius: 10px;
            }

            span {
                opacity: 0;
                transition: 0.3s;
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 500;
            }

            &:hover {
                span {
                    opacity: 1;
                }
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: #1f2327;
                    opacity: 0.9;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    border-radius: 10px;
                }
            }
        }
    }
}