.badge {
    &--availability {
        background-color: $green-light;
        width: 170px;
        height: 170px;
        box-shadow: inset 0px 10px 6px #003F2529;
        border: 10px solid $green-light;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $green-2;
        font-size: 48px;
        font-weight: 800;

        @include mobile {
            width: 150px;
            height: 150px;
        }

        &--bg {
            background-color: $green-3;
            border-radius: $border-radius;
        }

        span {
            font-size: $fs-lg;
            font-weight: 400;
            line-height: 30px;
        }
    }

    &--info-icon {
        letter-spacing: 0.64px;
        text-align: center;
        font-weight: 500;
        padding: 16px;
    
        &--bg{
            background-color: $gray-2;
            border-radius: $border-radius;
            width: 170px;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        @include mobile {
            width: 150px;
            height: 150px;
        }
    
        svg {
            margin-bottom: 16px;
        }
    
        span {
            font-size: $fs-md;
            line-break: anywhere;
            text-align: left;
        }

        &__subtext {
            color: $text-color;
            font-size: 13px;
            font-style: italic;
            margin-top: 6px;
        }
    }
}