.promotion-button {
    font-weight: 800;
    color: white;
    padding: 30px 0;
    width: 55px;
    box-shadow: 0px 7px 13px #00000040;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: $green-2;
    position: fixed;
    z-index: 999;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;

    span {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
    }

    @include mobile {
        width: 45px;
    }

    &:hover,
    &:focus,
    &:active {
       background-color: #3AAD7C;
    }

    &__link {

        @include mobile {
            margin-top: 40px;
            display: block;
        }
        img {
            width: 100%;
        }

        &-pdf {
            margin-bottom: 24px;
            display: block;
            color: $green-2;
            font-weight: 500;

            &:hover {
                color: $green-2;
                text-decoration: underline;
            }
        }
    }

    &__description {
        color: $text-color;
        margin-bottom: 24px;
    }
} 