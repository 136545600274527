.table {
    overflow: auto;
    &__title {
        text-align: center;
        padding: 20px;

        @include desktop {
            border-radius: $border-radius 20px 0 0;
            background-color: $green-light;
        }
    }

    &__button {
        padding: 30px 0 0;
        @include tablet-mobile {
            padding: 20px;
        }
        .button {
            width: 100%;
        }

        i {
            margin-left: 8px;
        }
    }
    &__etaj
    {
        text-align: left;
        &__vandut{
        span
        {opacity:0.3;};
    }
    }
    &__pret
    {
        text-align: right;
        &__vandut{
            text-align: right;
            span
            {opacity:0.3;};
        }
    }
    &__utila
    {
        text-align: center;
        &__vandut{
            text-align: center;
            span
            {opacity:0.3;};
        }       
    }
    
    table {
        width: 100%;

        thead {
            background-color: $green-2;
    
            th {
                color: white;
                font-weight: bold;
                font-size: $fs-md;
                text-transform: uppercase;
                padding: 14px 20px;
                text-align: center;
                white-space: nowrap;

                &:first-child {
                    text-align: left;
                }

                @include tablet-mobile {
                    padding: 14px 10px;
                }
            }
        }

        tbody {
            tr {
                
                &:nth-child(even) {
                    background-color: $gray-2;
                }

                &:nth-child(odd) {
                    background-color: $gray-1;
                }

                td {
                    padding: 14px 20px;
                    font-size: $fs-md;
                    font-weight: 500;
                    white-space: nowrap;

                    @include tablet-mobile {
                        padding: 14px 10px;
                    }

                    i {
                        color: $green-2;
                        font-size: 24px;

                        @include tablet-mobile {
                            font-size: 20px;
                        }
                    }

                }

                @include desktop {

                    &:last-child {
    
                        td {
                            &:first-child {
                                border-bottom-left-radius: 20px;
                            }
                            &:last-child {
                                border-bottom-right-radius: 20px;
                            }
                        }
                    }
                }

            }
        }
    }
}