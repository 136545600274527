.d-flex {
    display: flex;
}

.ai-center {
    align-items: center;
}

.jc-center {
    justify-content: center;
}

.jc-sb {
    justify-content: space-between;
}