.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(31, 35, 39, 0.9);
    z-index: 9999;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    white-space: normal;

    @include desktop {
        padding: 3rem;
    }

    &__inner {
        max-width: 830px;
        width: 100%;
        background-color: #fff;
        text-align: center;
        position: relative;

        @include desktop {
            border-radius: $border-radius;
        }

        @include tablet-mobile {
            min-height: 100%;
            max-width: 100%;
        }
    }
    
    &__header {
        padding: 30px;
        background-color: $gray-2;

        @include tablet-mobile {
            padding-top: 80px;
        }
        
        @include desktop {
            border-radius: 20px 20px 0 0;
        }

        h4 {
            font-weight: 800;
            line-height: 37px;
            
            @include tablet-mobile {
                 text-align: left;
            }
        }
    }

    &__body {
        padding: 30px;
    }

    &-close {
        background-color: white;
        box-shadow: $card-shadow;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 22px;
        
        @include desktop {
            position: absolute;
            border-radius: 50%;
            right: -20px;
            top: -20px;
        }

        @include tablet-mobile {
            width: 100%;
            background-color: white;
            justify-content: flex-end;
            padding: 16px 20px;
            box-shadow: 0px 3px 8px #0000001A;
            position: fixed;
        }
    }
}