.proiect {
    width: 100%;

    &__img {
        position: relative;
        background-image: url('../../assets/images/page-head.png');
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 131px;
        border-bottom: 5px solid $green-2;
        height: 100%;
        @include desktop {
            display: none;
        }
    }

    &__title {
        z-index: 99;

        h1 {
            color: $green-2;
            letter-spacing: 4.07px;
            text-transform: uppercase;
            font-weight: 900;
            margin: 0;
            font-size: 27px;
            top: unset;
            letter-spacing: 2px;
        }
    }

    &__content {
        height: 100%;
        width: 100%;
        z-index: 9;

        @include tablet-mobile {
            background-image: none;
            padding: 0;
            position: unset;
        }
    }

    &__cards {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include tablet-mobile {
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
    }

    &__card {
        width: 270px;
        height: 320px;
        background-color: white;
        box-shadow: $card-shadow;
        border-radius: $border-radius;
        z-index: 9;
        padding: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;


        @include tablet-mobile {
            width: 150px;
            height: 230px;
            padding: 20px 16px;

            h5 {
                font-size: $fs-md;
                line-height: 22px;
            }

            svg {
                height: 70px;
            }

            &:last-child {
                span {
                    font-size: 35px;

                    span {
                        font-size: 22px;
                    }
                }
            }
        }

        span {
            color: $green-2;
            font-size: 58px;
            font-weight: 800;
            display: flex;
            justify-content: center;

            @include mobile {
                font-size: 47px;
            }

            span {
                font-size: 30px;
            }
        }
        
        @include desktop {
            &:not(:last-child) {
                margin-right: 30px;
            }
        }

        // &:first-child,
        // &:nth-child(3) {
        //     margin-top: 8%;

        //     @include tablet-mobile {
        //         margin-top: 20px;
        //     }
        // }
        // &:last-child,
        // &:nth-child(2) {
        //     margin-top: 4%;

        //     @include tablet-mobile {
        //         margin-top: 20px;
        //     }
        // }
    }
}