body {
    font-size: $fs-lg;
    font-family: $font-primary;
    color: $text-color-dark;
    background: white;
}

html,
body,
#root {
    height: 100%;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    color: $title-color;
}

h4 {
    text-transform: uppercase;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 1.18px;
}

h3 {
    font-size: 48px;
    line-height: 52px;
    font-weight: 800;
    text-transform: uppercase;
}

h5 {
    font-size: 22px;
    line-height: 29px;
    font-weight: 800;
    text-transform: uppercase;
}

p {
    line-height: 30px;
}

a {
    cursor: pointer !important;
}

.container {
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 0 20px;

     * {
        cursor: unset;
    }

    &::after {
        content: none;
    }

    &::before {
        content: none;
    }
}

.wrapper {
    padding-top: 80px;
    height: 100%;

    @include tablet-mobile {
        padding-top: 116px;
        padding-bottom: 62px;
    }
}

.whatsapp-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    padding-left: 13px;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 13px;
    border-radius: 85px;
    z-index: 99999999;
    color: #fff;
    border: 1px solid transparent;
    background-color: #28a745;
    outline: none;
    cursor: pointer;

    @include tablet-mobile {
        bottom: 75px;
    }
}

.image-gallery-icon:hover {
    color: $green-3;
}
