.navbar {
    background-color: white;
    box-shadow: $nav-shadow;
    position: fixed;
    width: 100%;
    padding: 16px 0;
    z-index: 999;

    @include tablet-mobile {
        padding: 32px 0;
    }

    &__brand {
        width: 150px;
        position: absolute;
        top: 0;

        @include tablet-mobile {
            left: 0;
            width: 100px;
        }

        img {
            width: 100%;
            filter: drop-shadow(0px 8px 30px #0000004D);
            border-radius: 10px;
        }
    }

    &__contact {
        margin-left: 170px;

        > div {
            display: flex;
            align-items: baseline;
        }

        i {
            font-size: $fs-md;
            margin-right: 10px;
            color: $text-color-dark;
        }

        @include tablet-mobile {
            margin-left: 100px;
        }

        &-phone {
            font-weight: 500;
            font-size: $fs-sm;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            a:hover {
                color: $green-2;
            }
        }

        &-address {
            font-size: $fs-xs;
            color: $text-color;
            display: flex;
            align-items: center;

            @include mobile {
                line-height: 22px;
                font-weight: 500;
            }
        }
    }

    &__menu {
        
        &--desktop {
            font-weight: 600;
            font-size: $fs-sm;
            text-transform: uppercase;

            @include tablet-mobile {
                display: none;
            }

            .navbar__menu__link {
                padding: 5px 10px;
                cursor: pointer;
                
                &:not(:last-child) {
                    margin-right: 10px;
                }
        
                &--active {
                    background-color: $green-2;
                    color: white;
                    border-radius: 5px;
                }
            }
        }

        &--mobile {
            background-color: white;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            font-size: $fs-xs;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 3px solid $green-2;
            z-index: 99;

            @include desktop {
                display: none;
            }

            .navbar__menu__link {
                padding: 8px 14px;
                text-align: center;

                span {
                    opacity: 0.65;
                }

                svg {
                    fill: #B4BAC3;
                    margin-bottom: 5px;
                }
                path {
                    fill: #B4BAC2;
                }

                &--active {

                    span {
                        opacity: 1;
                    }

                    svg, path {
                        fill: $green-2;
                    }
                }
            }
        }

    }
}