.apartamente {
 
    &__cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 90px;

        @include desktop {
            padding-bottom: 120px;
            padding-top: 30px;
        }

        @include tablet {
            flex-wrap: wrap;
            justify-content: center;
        }

        @include mobile {
            flex-direction: column;
        }
    }
    &__card {
        box-shadow: $card-shadow;
        background-color: white;
        border-radius: $border-radius;
        width: 370px;
        height: 567px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 40px;
        text-align: center;

        @include mobile {
            width: 320px;
            margin-bottom: 50px;
        }
        @include tablet {
            margin: 16px;
            margin-bottom: 90px; 
        }

        @include desktop {
            margin-right: 30px;
        }
        
        .apartamente__availability-value {
        @include desktop{
            display: flex;
            flex-direction: column;
            gap: 8px;

            > span > span {
                display: none;
            }
        }
        @include mobile{
            display: inline-flex;
            gap: 8px;

        }
        }
        &__available {
            margin-bottom: 30px;
            margin-top: -120px;

            @include mobile {
                margin-top: -100px;
            }
        }

        &__svg {
            margin-top: -100px;

            @include mobile {
                width: 320px;
                margin-top: -70px;

                img {
                    width: 100%;
                }
            }
        }

        &__price {
            margin-bottom: 30px;

            span {
                font-weight: 500;
            }
        }
    }

    &__detail-card {
        width: 100%;
        box-shadow: $card-shadow;
        border-radius: $border-radius;
        position: relative;
        margin-left: auto;
        margin-right: auto;

        &__badge {
            position: absolute;
            display: flex;
            justify-content: center;
            width: 100%;
            top: -85px;

            @include mobile {
                top: -50px;
            }

            .badge--availability {
                @include mobile {
                    width: 100px;
                    height: 100px;
                    font-size: 30px;
                    line-height: 40px;
                }

                span {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
        }

        &__wrapper {
            display: flex;
        }

        &__img {
            width: 50%;
            padding: 40px;
            background-color: white;
            border-radius: $border-radius 0 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet-mobile {
                display: none;
            }
            
            img {
                width: 100%;
                max-width: 95%;
            }

        }

        &__info {
            width: 100%;
            padding: 40px;
            text-align: center;
            display: flex;
            border-radius: $border-radius;
            flex-direction: column;
            justify-content: space-between;
            background: white;
            
            h3 {
                @include mobile {
                    font-size: 20px;
                    line-height: 20px;
                }

            }

            @include tablet-mobile {
                .apartamente__availability-value {
                    margin-bottom: 8px;
                }    
            }

            @include mobile {
                padding: 24px 40px;
            }
            
            @include desktop {
                width: 50%;
                border-radius: 0 20px 20px 0;
                background-color: $gray-1;
            }

            &-mb-img {
                display: none;
                margin: 16px auto;

                @include tablet-mobile {
                    max-width: 70%;
                }

                img {
                    width: 100%;
                }

                @include tablet-mobile {
                    display: block;
                }
            }

            &__icons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 40px 0;

                @include mobile {
                    display: grid;
                    grid-template: 1fr 1fr / 1fr 1fr;
                    gap: 12px 8px;
                    margin: 0;

                    .badge--info-icon {
                        padding: 0;
                        display: flex;
                        align-items: center;
                        width: auto;
                        height: auto;

                        svg {
                            width: 25px;
                            height: auto;
                            margin-bottom: 0;
                            margin-right: 8px;
                        }

                        span {
                            font-size: $fs-xs;
                        }
                    }
                }
            }

            &__area {
                color: $text-color;
                letter-spacing: 0.27px;
                line-height: 30px;

                @include mobile {
                    display: none;
                }

                &--mb {
                    display: none;
                    font-weight: 500;
                    line-height: 30px;

                    @include mobile {
                        display: block;
                    }
                }
            }

            &__price {
                margin: 30px 0;

                @include mobile {
                    margin: 8px 0;
                }

                @include mobile {
                    .price {
                        font-size: 22px;
                        margin: 8px 0;

                        &.old {
                            font-size: 16px;
                            span {
                                font-size: 12px;
                            }
                        }

                        span {
                            font-size: $fs-sm;
                        }
                    }
                }
    
                span {
                    font-weight: 500;

                    @include mobile {
                        font-size: $fs-sm;
                    }
                }
            }

            .button {
                width: 100%;
                @include tablet-mobile {
                    width: max-content;
                    margin: 0 auto;
                }
            }
        }
    }

    &__details {

        @include  desktop {
            padding-bottom: 80px
        }

        > div {

            &:first-child {
                width: 100%;
    
                @include desktop {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 30px;
                }
                
                @include tablet-mobile {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        
        &__info {

            @include tablet-mobile {
                width: 100%;

                h3, h4 {
                    padding: 0 20px;
                }
            }
            &__area {
                color: $text-color;
                letter-spacing: 0.27px;
                line-height: 30px;
                margin-top: 40px;

                @include tablet-mobile {
                    padding: 0 20px;
                }
            }

            &__description {
                color: $text-color;
                line-height: 30px;

                @include tablet-mobile {
                    padding: 0 20px;
                }

                p {
                    margin-top: 40px;
                }
            }
        }

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet-mobile {
                width: 100%;
                background: white;
                box-shadow: 0px 0px 35px #00000029;
                padding: 40px 20px;
                margin-top: 30px;
            }

            img {
                width: 100%;
            }
        }

        &__icons {

            @include tablet-mobile {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: space-evenly;
                margin-top: 30px;
            }
            @include desktop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 50px;
                gap: 30px;
            }
        }

        &__plan {
            @include desktop {
                margin-top: 70px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
            }
        }

        &__mpc-list {

            @include desktop {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @include tablet-mobile {
                margin: 30px 0;
                padding: 0 20px;
            }

            ul {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
                column-gap: 10px;

                @include mobile {
                    -webkit-column-count: 1;
                    -moz-column-count: 1;
                    column-count: 1;
                    column-gap: 0;
                }
    
                li {
                    font-size: $fs-md;
                    font-weight: 500;
                    margin-bottom: 24px;

                    &::before {
                        content: '\f00c';
                        font: $font-awesome;
                        color: $green-2;
                        margin-right: 16px;
                    }
                }
            }
        }

        &__prices {
            background-color: $gray-2;
            padding: 90px 0;
            text-align: center;

            @include tablet-mobile {
                padding: 40px 0 50px;
            }

            &-row { 
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include tablet-mobile {
                    flex-wrap: wrap;
                }
            }

            &-col {
                text-align: center;
                padding-bottom: 40px;
                margin: 0 auto 8px;
                @include desktop {
                    width: 50%;
                }
            }

            &-available {
                margin: 24px 0 32px;

                span {
                    &:first-child {
                        color: $green-2;
                    }

                    &:last-child {
                        color: $text-color;
                    }
                }
            }

            .price {
                margin-right: auto;
                margin-left: auto;
                width: max-content;

                @include desktop {
                    position: relative;
                    
                    &.old{
                        &::after {
                            content: none;
                        }
                    }
                    
                    &::after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: $green-2;
                        position: absolute;
                        bottom: -70px;
                    }

                }
            }
        }
    }

    &__availability {

        @include  desktop {
            padding-bottom: 120px;
        }

        h3, h4 {
            text-align: center;
        }

        h4 {
            margin-bottom: 8px;
        }

        &-value {
            margin-top: 24px;

            span {
                &:first-child {
                    color: $green-2;
                }

                &:last-child {
                    color: $text-color;
                }
            }
        }

        &-row {
            width: 100%;
            display: grid;
            grid-template-columns: 1.8fr 1fr;
            column-gap: 30px;
            margin-top: 80px;

            @include tablet-mobile {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
            }
        }

        &__img {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            @include tablet-mobile {
                margin-bottom: 24px;
            }
            @include desktop { 
                
            }
 
            img {
                width: 100%;

                @include desktop {
                    box-shadow: $card-shadow;
                    border-radius: 20px;
                    border: 8px solid white;
                }
            }
        }
    }

    &__list {

        > div {
            @include desktop {
                margin-top: 100px;
            }

            @include mobile {
                margin-top: 50px;
            }
            
            @include tablet {
                margin-top: 90px;
            }
        }
    }
}
