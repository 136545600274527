.button {
    font-weight: 800;
    color: white;
    padding: 0 30px;
    height: 55px;
    box-shadow: 0px 7px 13px #00000040;
    border: 1px solid $green-1;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;

    &--default {
        background-color: $green-2;
    }

    &:hover,
    &:focus,
    &:active {
        opacity: 0.8;
    }
} 